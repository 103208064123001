<template>
  <div>
    <t-loading v-model="loading" />
    <t-head :header="getNamePage" :breadcrumb="getBreadcrumb" />
    <t-container>
      <v-card>
        <v-card-text>
          <v-form ref="form">
            <v-row class="mt-0">
              <v-col cols="12" sm="12" md="6" class="pt-0">
                <v-text-field
                  v-model="objData.cnpj"
                  :label="$t('companyCode')"
                  outlined
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="12" sm="12" md="6" class="pt-0">
                <v-select
                  v-model="objData.provider"
                  :rules="[rules.required]"
                  :items="providersList"
                  label="Providers"
                  outlined
                />
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col>
                <ButtonsActionForm @clickSave="saveCompaniesConfig" @clickCancel="cancel" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </t-container>
  </div>
</template>

<script>
import ButtonsActionForm from '@/components/ButtonsActionForm.vue'
import mixinRouter from '@/mixins/mixinRouter'
import mixinAlert from '@/mixins/mixinAlert'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: { ButtonsActionForm },
  mixins: [mixinRouter, mixinAlert],
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      objData: {
        cnpj: null,
        provider: null,
      },
      rules: {
        required: (value) => !!value || this.$i18n.t('required'),
      },
      providersList: ['Driv.in', 'E-Transportador'],
      loading: false,
    }
  },
  computed: {
    ...mapGetters('companiesConfigStore', {
      getCompaniesConfig: 'getData',
    }),
    getNamePage() {
      return this.isUpdate ? this.$i18n.t('edit') : this.$i18n.t('add')
    },
    getBreadcrumb() {
      return this.$i18n.t('companiesConfig')
    },
    isUpdate() {
      return this.id !== null && this.id !== ''
    },
  },
  methods: {
    ...mapActions('companiesConfigStore', {
      addCompaniesConfig: 'setAddData',
      updateCompaniesConfig: 'setUpdateData',
      setCompaniesConfig: 'setData',
      clearCompaniesConfig: 'clearState',
    }),
    async saveCompaniesConfig() {
      let valid = this.$refs.form.validate()
      if (!valid) return
      let confirm = await this.mixinAlertConfirm()
      if (!confirm) return
      this.mixinAlertLoading()
      try {
        let data = this.getDataCompaniesConfig()
        if (this.isUpdate) {
          await this.updateCompaniesConfig(data)
        } else {
          await this.addCompaniesConfig(data)
        }
        await this.mixinAlertSuccess()
        this.clear()
        this.backPage()
      } catch (error) {
        console.error(error)
        await this.mixinAlertError()
        return
      }
    },
    cancel() {
      this.redirect({
        name: 'companiesConfig',
      })
    },
    getDataCompaniesConfig() {
      let { cnpj, provider } = this.objData
      let data = {
        cnpj,
        provider,
      }
      if (this.isUpdate) {
        data = {
          ...data,
          id: this.id,
        }
      }
      return data
    },
    backPage() {
      this.redirect({
        name: 'companiesConfig',
      })
    },
    clear() {
      this.$refs.form.reset()
      this.clearCompaniesConfig()
      this.objData = {
        cnpj: null,
        provider: true,
      }
    },
    setData() {
      let { cnpj, provider } = this.getCompaniesConfig
      this.objData = {
        cnpj,
        provider,
      }
    },
  },
  async mounted() {
    this.loading = true
    try {
      this.clearCompaniesConfig()
      if (this.isUpdate) {
        await this.setCompaniesConfig({ id: this.id })
        this.setData()
      }
      this.loading = false
    } catch (error) {
      console.error(error)
      this.loading = false
      this.mixinAlertError()
      return
    }
  },
}
</script>
